const code = () => {
  const languageSelectors = document.querySelectorAll('.language-selector');
  const codeSnippets = document.querySelectorAll('.code-snippet');

  languageSelectors.forEach(s => {
    s.classList.remove('active');
    // @ts-ignore
    s.querySelector('svg').style.fill = '#6b7280';
  });

  // Set initial state (Python active)
  const defaultSelector = document.querySelector('[data-language="python"]');
  if (defaultSelector) {
    defaultSelector.classList.add('active');
    // @ts-ignore
    defaultSelector.querySelector('svg').style.fill = '#9EFF7D';
  }

  // Show Python code by default
  const pythonCode = document.getElementById('python-code');
  if (pythonCode) {
    pythonCode.classList.remove('hidden');
  }

  // Your existing click handlers
  languageSelectors.forEach(selector => {
    selector.addEventListener('click', function(e) {
      e.preventDefault();

      // Update active state of selectors
      languageSelectors.forEach(s => {
        s.classList.remove('active');
        // @ts-ignore
        s.querySelector('svg').style.fill = '#6b7280';
      });
      this.classList.add('active');
      this.querySelector('svg').style.fill = '#9EFF7D';

      // Show selected code snippet
      const language = this.dataset.language;
      codeSnippets.forEach(snippet => {
        snippet.classList.add('hidden');
      });
      document.getElementById(`${language}-code`)?.classList.remove('hidden');
    });
  });
}

export default code;
